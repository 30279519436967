<template>
  <div
    v-if="title ?? label?.title"
    class="ui-label"
    :class="[labelAccentColor, labelBackground]"
  >
    <div class="ui-label__title" v-text="title ?? label?.title" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { UiLabelProps } from '~/types'
import useTheming from '~ui/composables/useTheming'

const props = defineProps<{ label?: UiLabelProps } & UiLabelProps>()

const accentColor = props.accentColor ?? props.label?.accentColor
const withBackground = props.withBackground || props.label?.withBackground

const labelAccentColor = useTheming(accentColor, 'variant')
const labelBackground = computed(() =>
  withBackground ? 'ui-label--background' : ''
)
</script>

<style lang="scss">
.ui-label {
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;

  &__title {
    display: flex;
    align-items: center;
    gap: em(12px);
    width: fit-content;
    padding-block: em(8px);

    &::before {
      content: '';
      display: block;
      height: rem(10px);
      width: rem(10px);
      background-color: var(--variant__theme-color);
    }
  }

  &--background &__title {
    color: var(--variant__theme-color);
    background-color: var(--variant__gradient-100);
    padding-inline: em(16px);
    border-radius: em(2px);

    &::before {
      content: none;
    }
  }
}
</style>
